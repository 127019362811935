<template lang="pug">
  Transition(
    name="fade"
    tag="div"
    class="transition-container"
  )
    Confirm(
      v-if="isOpen"
      key="confirm"
      :title="confirmData.title"
      :show-cancel="isDefaultState"
      @resolve="handleAnswer('resolve')"
      @reject="handleAnswer('reject')"
    )
      component(
        v-if="confirmData.component !== undefined"
        :is="confirmData.component"
      )
</template>

<script>
  // misc
  import { bus } from "@/config"

  const ANSWER_CONFIG = {
    titleAfter: undefined,
    handler: new Function()
  }
  const defaultData = {
    title: "",
    resolve: { ...ANSWER_CONFIG },
    reject: { ...ANSWER_CONFIG },
    component: null
  }
  const DEFAULT_STATE = "DEFAULT_STATE"
  const AFTER_ANSWER_STATE = "AFTER_ANSWER_STATE"

  export default {
    components: {
      Confirm: () => import("@/components/elements/AppConfirm/Confirm")
    },
    data() {
      return {
        isOpen: false,
        state: DEFAULT_STATE,
        confirmData: {
          ...defaultData
        }
      }
    },
    created() {
      bus.on("open-confirm-dialog", ({ title, resolve, reject, component }) => {
        if (!title) {
          throw new Error('"title" is required')
        }
        this.confirmData = {
          ...defaultData,
          component,
          title,
          resolve: {
            ...ANSWER_CONFIG,
            ...resolve
          },
          reject: {
            ...ANSWER_CONFIG,
            ...reject
          }
        }
        this.state = DEFAULT_STATE
        this.isOpen = true
      })
    },
    computed: {
      isDefaultState() {
        return this.state === DEFAULT_STATE
      }
    },
    methods: {
      close() {
        this.isOpen = false
      },

      handleAnswer(type) {
        const { isDefaultState, close, confirmData } = this
        const { handler, titleAfter } = confirmData[type]
        if (isDefaultState) {
          handler()
          if (titleAfter === undefined) {
            close()
          } else {
            this.confirmData.title = titleAfter
            this.state = AFTER_ANSWER_STATE
          }
        } else {
          close()
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/transitions.sass"
</style>
